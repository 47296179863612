/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
//import useTourState from "../../state/tour";
export default function Model(props) {
  const group = useRef();
  const { scene: low } = useGLTF(
    "/gltf/new/AL_24_03_29_512.glb",
    "https://www.gstatic.com/draco/versioned/decoders/1.4.1/"
  );
  let [high, setHigh] = useState(null);
  useEffect(() => {
    var dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(
      "https://www.gstatic.com/draco/versioned/decoders/1.4.1/"
    );
    dracoLoader.setDecoderConfig({ type: "js" });
    const loader = new GLTFLoader();
    loader.setDRACOLoader(dracoLoader);
    setTimeout(() => {
      loader.load("/gltf/new/AL_24_03_29_4096.glb", function (gltf) {
        setHigh(gltf.scene);
      });
    }, 10000);
  }, [low]);

  return <primitive ref={group} {...props} object={high || low}></primitive>;
}
