/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import useTourState from "../../state/tour";
import useHoverState from "../../state/hoverstate";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/gltf/new/PP_24_03_29_512_DRACO.glb",
    "https://www.gstatic.com/draco/versioned/decoders/1.4.1/"
  );
  const { actions } = useAnimations(animations, group);
  let { selectedPoint } = useTourState();
  let { currentHover } = useHoverState();
  let [line, setLine] = useState(0);
  useEffect(() => {
    if (currentHover > 0) {
      setLine((l) => l + 1);
    }
  }, [currentHover]);
  //console.log(actions);
  useEffect(() => {
    console.log(actions);
    actions["animation_line_02_2024"].setDuration(3);
    actions["animation_line_01_2024"].setDuration(3);
    actions["animation_line_02_2024"].play();
    actions["animation_line_01_2024"].play();
  }, [actions]);
  console.log(nodes);
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        geometry={nodes.PilotPlantMesh_2024001.geometry}
        material={nodes.PilotPlantMesh_2024001.material}
        position={[72.363868713379, 2.027682542801, 68.883682250977]}
      />
      <mesh
        name="animated_line_02"
        scale={currentHover > 0 && line % 2 === 0 ? 1 : 0}
        geometry={nodes.animated_line_02.geometry}
        material={materials.line_red}
      />
      <mesh
        name="animated_line_01"
        scale={currentHover > 0 && line % 2 === 1 ? 1 : 0}
        geometry={nodes.animated_line_01.geometry}
        material={materials.line_yellow}
      />
      <group position={[-2.4, 0, -2.6]}>
        {selectedPoint <= 11 - 8 ? (
          <mesh
            geometry={nodes.castfilm_1_2024001.geometry}
            material={nodes.castfilm_1_2024001.material}
            position={[58.181884765625, 2.414881229401, 51.340362548828]}
          />
        ) : selectedPoint === 12 - 8 ? (
          <mesh
            geometry={nodes.castfilm_2_2024001.geometry}
            material={nodes.castfilm_2_2024001.material}
            position={[58.170261383057, 2.412911176682, 51.323394775391]}
          />
        ) : selectedPoint === 13 - 8 ? (
          <mesh
            geometry={nodes.castfilm_3_2024001.geometry}
            material={nodes.castfilm_3_2024001.material}
            position={[58.160697937012, 2.41420674324, 51.387573242188]}
          />
        ) : (
          <mesh
            geometry={nodes.castfilm_4_2024001.geometry}
            material={nodes.castfilm_4_2024001.material}
            position={[58.151794433594, 2.422712087631, 51.367561340332]}
          />
        )}
      </group>
    </group>
  );
}
